import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

const IndexPage = () => {

	var seo = { metaTitle:'Portfolio page | Dapit', metaDescription:"All of Dapit's porject listed with a description and images.", shareImage:"/images/LandingPage-Dapit.png"};

	return (
		<Layout pageen="/portfolio" pagelb="/portfolio" seo={seo}>
			<section className="container-fluid block mt-5 mb-5">
				<div className="row mt-4">
					<div className="col-12 col-lg-5 title">
						<p className="date">20 April 2021</p>
						<h2>Nocturnal E-Sports A.S.B.L</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Website</h3>
							<p>
								Nocturnal E-sports new website is a platform where they post their news and where you can find detailed information about their teams in the different E-sports. About those teams you find detailed informations about the tournament with their result in which they compete.
							</p>
							<p>
								The page also introduces their members and what they do.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/Nocturnal-Website.jpg" alt="Image, Nocturnal E-sports Screenshot of the website's landing page."/>
						<div className="caption">
							<div className="box">
								<span className="header">Nocturnal E-sport</span><br/>
								'Landing Page'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Website</h3>
						<p>
							Nocturnal E-sports new website is a platform where they post their news and where you can find detailed information about their teams in the different E-sports. About those teams you find detailed informations about the tournament with their result in which they compete.
						</p>
						<p>
							The page also introduces their members and what they do.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">09 December 2020</p>
						<h2>MX-5 NA in night</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Photography</h3>
							<p>
								Shooting for Sacha with his quiet newly treasure of an old Mazda Mx-5 NA from 1996.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<div className="carousel slide carousel-fade" id="mx5Carousel" data-bs-ride="carousel" data-bs-interval="false">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="/images/mx5-first-night-1.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-2-v2.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-5.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-6.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
							</div>
						</div>
						<div className="caption">
							<div className="box">
								<span className="header">MX-5 NA</span><br/>
								'Night shoot'
							</div>
						</div>
						<div className="carousel-control-box">
							<button className="carousel-control carousel-control-prev" type="button" data-bs-target="#mx5Carousel" data-bs-slide="prev">
								<i class="fas fa-caret-left"></i>
							</button>
							<button className="carousel-control carousel-control-next" type="button" data-bs-target="#mx5Carousel" data-bs-slide="next">
								<i class="fas fa-caret-right"></i>
							</button>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content mb-5">
						<h3>Photography</h3>
						<p>
							Shooting for Sacha with his quiet newly treasure of an old Mazda Mx-5 NA from 1996.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 title">
						<p className="date">03 November 2020</p>
						<h2>De Grenge Krees</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Illustration, Broschure</h3>
							<p>De Grénge Krees is an initative by the Jugend Rout Kräiz to bring more sustainability to their holiday camps. They wanted to create a grocery shopping guide which the guardians can use to inform themself about what to pay attention on their grocery shopping tour.</p>
							<p>We worked together to create a beautiful PDF file with their knowledge about the theme sustainability and my illustrations to capture the attention.</p>
							<p>After the initial idea of the guide in form of the PDF, we expanded the idea to present this guide in combination with a website.</p>
							<ul>
								<li>
									<a href="http://grengekrees.dapit.lu/assets/Akafsguide-DeGrengeKrees.pdf" target="_blank">
										<i class="fas fa-file-pdf"></i>
									</a>
								</li>
								<li>
									<a href="http://grengekrees.dapit.lu" target="_blank">
										<i className="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/jugendroutkraiz/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/croixrougeluxembourgeoisejeunesse" target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/degrengekrees.jpg" alt="Image, Screenshot of the broschure's website."/>
						<div className="caption">
							<div className="box">
								<span className="header">De Grenge Krees</span><br/>
								'Website'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Illustration, Broschure</h3>
						<p>De Grénge Krees is an initative by the Jugend Rout Kräiz to bring more sustainability to their holiday camps. They wanted to create a grocery shopping guide which the guardians can use to inform themself about what to pay attention on their grocery shopping tour.</p>
						<p>We worked together to create a beautiful PDF file with their knowledge about the theme sustainability and my illustrations to capture the attention.</p>
						<p>After the initial idea of the guide in form of the PDF, we expanded the idea to present this guide in combination with a website.</p>
						<ul>
							<li>
								<a href="http://grengekrees.dapit.lu/assets/Akafsguide-DeGrengeKrees.pdf" target="_blank">
									<i class="fas fa-file-pdf"></i>
								</a>
							</li>
							<li>
								<a href="http://grengekrees.dapit.lu" target="_blank">
									<i className="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/jugendroutkraiz/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/croixrougeluxembourgeoisejeunesse" target="_blank">
									<i className="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">25 October 2020</p>
						<h2>Fairy maiden</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Photography</h3>
							<p>
								Edited some photos for a friend. She does cosplay and makes her own Pokémon terrariums.
							</p>
							<ul>
								<li>
									<a href="https://www.etsy.com/de/shop/Hinymee" target="_blank">
										<i class="fas fa-shopping-basket"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/hinymee/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/nomekop_terraria/" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<div className="carousel slide carousel-fade" id="fairymaidenCarousel" data-bs-ride="carousel" data-bs-interval="false">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="/images/Lisi-Maid-GreenHair-Color-InstaSquareSize.jpg" alt="Image, Hinymee as a maiden with green hair."/>
								</div>
								<div className="carousel-item">
									<img src="/images/Lisi-Elf-2-sw.jpg" alt="Image, Hinymee as an elf in black white."/>
								</div>
								<div className="carousel-item">
									<img src="/images/Lisi-Elf-1.jpg" alt="Image, Hinymee as an elf."/>
								</div>
							</div>
						</div>
						<div className="caption">
							<div className="box">
								<span className="header">Fairy maiden</span><br/>
								'Casual edit'
							</div>
						</div>
						<div className="carousel-control-box">
							<button className="carousel-control carousel-control-prev" type="button" data-bs-target="#fairymaidenCarousel" data-bs-slide="prev">
								<i class="fas fa-caret-left"></i>
							</button>
							<button className="carousel-control carousel-control-next" type="button" data-bs-target="#fairymaidenCarousel" data-bs-slide="next">
								<i class="fas fa-caret-right"></i>
							</button>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content mb-5">
						<h3>Photography</h3>
						<p>
							Edited some photos for a friend. She does cosplay and makes her own Pokemon terrariums.
						</p>
						<ul>
							<li>
								<a href="https://www.etsy.com/de/shop/Hinymee" target="_blank">
									<i class="fas fa-shopping-basket"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/hinymee/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/nomekop_terraria/" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 title">
						<p className="date">30 April 2019</p>
						<h2>Du d'Welt an ech</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Website</h3>
							<p>Du d'Welt an Ech is a person, who informes you about everything around a healthier earth. She spends a lot of time to find different ways on how you could change little things in your life to make a better impact on the earth.</p>
							<ul>
								<li>
									<a href="http://www.dudweltanech.lu/" target="_blank">
										<i class="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/dudweltanech/" target="_blank">
										<i class="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/dudweltanech.jpg" alt="Image, Screenshot of Du d'Welt an ech's website."/>
						<div className="caption">
							<div className="box">
								<span className="header">Du d'Welt an Ech</span><br/>
								'Best person'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Website</h3>
						<p>Du d'Welt an Ech is a person, who informes you about everything around a healthier earth. She spends a lot of time to find different ways on how you could change little things in your life to make a better impact on the earth.</p>
						<ul>
							<li>
								<a href="http://www.dudweltanech.lu/" target="_blank">
									<i class="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/dudweltanech/" target="_blank">
									<i class="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">12 Februar 2018</p>
						<h2>Cracking Mechanics</h2>
						<div className="d-none d-lg-inline">
							<span className="line"></span>
							<h3>Website</h3>
							<p>Cracking Mechanics is a lifestyle channel for a special kind of motorcycle lovers. You see them doing reviews, track days, events and all kind of crazy stuff.</p>
							<ul>
								<li>
									<a href="http://crackingmechanics.com/" target="_blank">
										<i class="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="https://www.youtube.com/user/CrackingMechanics" target="_blank">
										<i class="fab fa-youtube"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/cramec_/" target="_blank">
										<i class="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<img src="/images/crackingmechanics.jpg" alt="Image, Screenshot of cracking mechanics' website."/>
						<div className="caption">
							<div className="box">
								<span className="header">Cracking Mechanics</span><br/>
								'Website'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Website</h3>
						<p>Cracking Mechanics is a lifestyle channel for a special kind of motorcycle lovers. You see them doing reviews, track days, events and all kind of crazy stuff.</p>
						<ul>
							<li>
								<a href="http://crackingmechanics.com/" target="_blank">
									<i class="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="https://www.youtube.com/user/CrackingMechanics" target="_blank">
									<i class="fab fa-youtube"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/cramec_/" target="_blank">
									<i class="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
